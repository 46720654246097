import { useState, useContext } from "react";
import { Navigate } from "react-router";
import { Heading, Text, Box, Button, ListItem, UnorderedList } from "@chakra-ui/react";
import { Center, Card, CardBody, FormControl, VStack, FormLabel } from "@chakra-ui/react";
import { Input, InputGroup, InputRightElement, IconButton, Alert, AlertIcon } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { useForm } from "react-hook-form";

import { css } from "@emotion/css";

import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "../components/config/auth";

import { LoginContext } from "../context/loginContext";
import { CredentialsContext } from "../context/credentialsContext";
import { CookieStorage } from "amazon-cognito-identity-js";

Amplify.configure({ Auth: AwsConfigAuth });

type FormInputs = {
  newPassword: string,
  confirmPassword: string
}

export default function NewPassword() {

  const { loginUsername, setLoginUsername, tempPassword, setTempPassword } = useContext(
    CredentialsContext
  );

  const [loading, setLoading] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);

  const [showNew, setShowNew] = useState(false);
  const handleClickNewPass = () => setShowNew(!showNew);

  const [showConfirm, setShowConfirm] = useState(false);
  const handleClickConfirmPass = () => setShowConfirm(!showConfirm);

  const { register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitted }
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    }
  });

  const password = watch('newPassword');

  const newPassword = async (username: string, tempPassword: string, newPassword: string) => {
    try {
      const user = await Auth.signIn(username, tempPassword);
      const response = await Auth.completeNewPassword(user, newPassword);

      setPasswordChange(true);
      localStorage.removeItem('NewPasswordRequired');
    }
    catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (data: FormInputs) => {
    if (setLoading) setLoading(true);
    if (loginUsername && tempPassword) {
      newPassword(loginUsername, tempPassword, data.newPassword);
    }
    if (setLoading) setLoading(false);
  };

  if (passwordChange) {
    return <Navigate to="/myExperiments" />;
  }

  return (
    <>
      <Center minHeight="calc(100vh - 263px)" marginY="auto">
        <Card width="50vw" marginRight="20px" align="center" >
          <CardBody width='100%'>
            <Heading
              as="h2"
              size="md"
              color={"blue.700"}
              marginLeft="10px"
              marginBottom="20px"
            >
              Set New Password
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl width="75%" minWidth="300px" maxWidth='500px' mx="auto">
                <VStack spacing='20px'>
                  <Box width='100%'>
                    <FormLabel alignSelf='flex-start'>New Password</FormLabel>
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        type={showNew ? 'text' : 'password'}
                        placeholder="New Password"
                        {...register('newPassword', {
                          required: { value: true, message: 'New password required' },
                          minLength: { value: 8, message: 'Minimum length should be 8 characters' },
                          maxLength: { value: 30, message: 'Maximum length should be 30 characters' },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,30}$/gm,
                            message: 'Password must match criteria'
                          }
                        })}
                        focusBorderColor="gray.600"
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Show password"
                          variant='link'
                          onClick={handleClickNewPass}
                          icon={showNew ? <ViewOffIcon /> : <ViewIcon />}
                        />
                      </InputRightElement>
                    </InputGroup>
                    {errors.newPassword &&
                      <Text
                        fontSize='xs'
                        color='red.600'
                      >
                        ⚠ {errors.newPassword.message}
                      </Text>}
                  </Box>

                  <Box width='100%'>
                    <FormLabel alignSelf='flex-start'>Confirm Password</FormLabel>
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        type={showConfirm ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        focusBorderColor="gray.600"
                        {...register('confirmPassword', {
                          required: 'Confirm password is required',
                          validate: value => value === password || 'Passwords do not match'
                        })}
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Show password"
                          variant='link'
                          onClick={handleClickConfirmPass}
                          icon={showConfirm ? <ViewOffIcon /> : <ViewIcon />}
                        />
                      </InputRightElement>
                    </InputGroup>
                    {errors.confirmPassword &&
                      <Text
                        fontSize='xs'
                        color='red.600'
                      >
                        ⚠ {errors.confirmPassword.message}
                      </Text>}
                  </Box>

                  {/* {isSubmitted && 
                          <Alert status='success'>
                            <AlertIcon />
                            Modified password successfully!
                          </Alert>} */}

                  <Button
                    type='submit'
                    fontWeight={700}
                    isLoading={loading}
                    backgroundColor={"red.700"}
                    color={"white"}
                    _hover={{ bg: "red.800" }}
                    width='40%'
                  >
                    Submit
                  </Button>
                </VStack>
              </FormControl>
            </form>
          </CardBody>
        </Card>

        <Card>
          <CardBody marginX="10px" marginY="10px">
            <Text marginBottom="10px" fontSize="15px">Your password must include:</Text>
            <UnorderedList  fontSize="13px">
              <ListItem>Between 8 and 30 characters</ListItem>
              <ListItem>At least one number</ListItem>
              <ListItem>At least one upper case letter</ListItem>
              <ListItem>At least one lower case letter</ListItem>
              <ListItem>At least one special character</ListItem>
            </UnorderedList>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}