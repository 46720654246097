import { useEffect, useState } from "react";

import { Heading, Text } from "@chakra-ui/react";
import { ListItem, List } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { getApi } from "../utils/api";
import { getCookie } from "../utils/getCookie";

type experimentProps = {
  id: string;
  experiment_name: string;
  is_active: boolean;
  created_at: string;
  description: string;
  instructions_file_key: string;
};

export default function MyExperiments() {

  const [experiments, setExperiments] = useState<experimentProps[]>([]);

  const cookieData = getCookie(decodeURIComponent(document.cookie));
  const userId = cookieData.userData.username;
  const [noData, setNoData] = useState('');

  useEffect(() => {
    const api = async () => {
      const api_path = "/users/experiments?userId=" + userId;
      const experiments_response = await getApi(api_path);
      const experiments_jsonData = await experiments_response.json();
      
      setExperiments(experiments_jsonData);

      if(experiments_response['status'] === 400) {
        // setExperiments([]);
        setNoData('User has no assigned experiments');
      }
    };
    api();
  }, [noData]);

  if (noData.length > 0){
    return (
      <>
      <Heading as="h2" size="lg" paddingY={"15px"} color={"blue.700"}>
        My Experiments
      </Heading>
      <Text>{noData}</Text>
      </>
    );
  }
  else if (!experiments.length){
    return (
      <>
      <Heading as="h2" size="lg" paddingY={"15px"} color={"blue.700"}>
        My Experiments
      </Heading>
      <Text>Loading...</Text>
      </>
    );
  }
  else {
  return (
    <>
      <Heading as="h2" size="lg" paddingY={"15px"} color={"blue.700"}>
        My Experiments
      </Heading>
      <List spacing={"6"} marginLeft={'20px'}>
        {experiments.map((item, index) => (
          <ListItem key={item.id}>
            <Link to={`/experiments/${item.id}`}> 
              <Text 
                fontSize={'2xl'} 
                fontWeight={'bold'} 
                color={"gray.600"}
                _hover={{color: 'blue.600'}}
              >{item.experiment_name}</Text>
            </Link>
            <Text fontSize="lg" m={"0 10px"} color={'gray.600'}>{item.description}</Text>
          </ListItem>
        ))}
        </List>
    </>
  );
        }
}
