import { useState, useContext, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Accordion, AccordionButton, AccordionItem, AccordionIcon, Center, Heading, HStack, Text, AccordionPanel, FormLabel, InputGroup, InputRightElement, IconButton, FormErrorMessage, VStack } from "@chakra-ui/react";
import { Card, CardBody, CardHeader, Box } from "@chakra-ui/react";
import { FormControl, Input, Button } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";

import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "../components/config/auth";

import { getCookie } from "../utils/getCookie";
import { LoginContext } from "../context/loginContext";
import { ErrorSection } from "aws-amplify-react";
import { time } from "console";

type FormInputs = {
  prevPassword: string,
  newPassword: string,
  confirmPassword: string
}


export default function Profile() {
  document.title = "CLaaS - Profile";

  const cookieData = getCookie(decodeURIComponent(document.cookie));
  const userData = cookieData.userData;

  const userLevel = (userData.userLevel).charAt(0).toUpperCase() + (userData.userLevel).slice(1);

  const { loading, setLoading } = useContext(
    LoginContext
  );

  const [showPrev, setShowPrev] = useState(false);
  const handleClickPrevPass = () => setShowPrev(!showPrev);

  const [showNew, setShowNew] = useState(false);
  const handleClickNewPass = () => setShowNew(!showNew);

  const [showConfirm, setShowConfirm] = useState(false);
  const handleClickConfirmPass = () => setShowConfirm(!showConfirm);

  const { register, 
    handleSubmit, 
    watch, 
    reset, 
    formState: { errors, isSubmitSuccessful, isSubmitted }
   } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      prevPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  const password = watch('newPassword');

  const changePassword = async (prevPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.changePassword(user, prevPassword, newPassword);
    }
    catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (data: FormInputs) => {
    changePassword(data.prevPassword, data.newPassword);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        prevPassword: '',
        newPassword: '',
        confirmPassword: ''
      }, { keepIsSubmitted: true })
    }
  }, [isSubmitSuccessful]);

  // const { userId } = React.useContext(
  //   UserContext
  // );

  // const [user, setUser] = useState<userProps>();

  // useEffect(() => {
  //   const api = async() => {
  //     const path = "https://7gmx67c7ma.execute-api.us-west-2.amazonaws.com/dev/users/" +  userId;
  //     const options = {
  //       method: "GET",
  //       headers: {
  //         'Accept': 'application/json'
  //       }
  //     };

  //     const data = await fetch(path, options);
  //     const jsonData = await data.json();

  //     setUser(jsonData);
  //   };

  //   api();
  // }, []);

  return (
    <>
      <Center>
        <Card width="90vw" margin="30px auto">
          <CardHeader>
            <Heading
              as="h2"
              size="md"
              color={"blue.700"}
              mr={3}
            >
              Profile
            </Heading>
          </CardHeader>
          <CardBody marginTop="-10px">
            <HStack width={"90%"} mx={"auto"}>
              <Text paddingRight='10px'>
                First Name:<br />
                Last Name:<br />
                Email:<br />
                User Level:<br />
              </Text>
              <Text fontWeight="bold">
                {userData.firstName}<br />
                {userData.lastName}<br />
                {userData.email}<br />
                {userLevel}<br />
              </Text>
            </HStack>
          </CardBody>
        </Card>
      </Center>

      <Center>
        <Card width="90vw" margin="0 auto" align="center">
          <CardBody width='100%'>
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton width={"auto"} padding={0} style={{ backgroundColor: 'transparent' }}>
                  <Heading
                    as="h2"
                    size="md"
                    color={"blue.700"}
                    mr={3}
                  >
                    Modify Password
                  </Heading>
                  <AccordionIcon color={"blue.800"} />
                </AccordionButton>

                <AccordionPanel border='none'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl width="50%" minWidth="300px" maxWidth='500px' mx="auto">
                      <VStack spacing='20px'>
                        <Box width='100%'>
                          <FormLabel mt={5} alignSelf='flex-start'>Previous Password</FormLabel>
                          <InputGroup>
                            <Input
                              autoComplete="off"
                              type={showPrev ? 'text' : 'password'}
                              placeholder="Previous Password"
                              {...register('prevPassword', {
                                required: { value: true, message: 'Current password required' }
                              })}
                              focusBorderColor="gray.600"
                            />
                            <InputRightElement>
                              <IconButton
                                aria-label="Show password"
                                variant='link'
                                onClick={handleClickPrevPass}
                                icon={showPrev ? <ViewOffIcon /> : <ViewIcon />}
                              />
                            </InputRightElement>
                          </InputGroup>
                          {errors.prevPassword &&
                            <Text
                              fontSize='xs'
                              color='red.600'
                            >
                              ⚠ {errors.prevPassword.message}
                            </Text>}
                        </Box>

                        <Box width='100%'>
                          <FormLabel alignSelf='flex-start'>New Password</FormLabel>
                          <InputGroup>
                            <Input
                              autoComplete="off"
                              type={showNew ? 'text' : 'password'}
                              placeholder="New Password"
                              {...register('newPassword', {
                                required: { value: true, message: 'New password required' },
                                minLength: { value: 8, message: 'Minimum length should be 8 characters' },
                                maxLength: { value: 30, message: 'Maximum length should be 30 characters' },
                                pattern: {
                                  value: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,30}$/gm,
                                  message: 'Password must match criteria'
                                }
                              })}
                              focusBorderColor="gray.600"
                            />
                            <InputRightElement>
                              <IconButton
                                aria-label="Show password"
                                variant='link'
                                onClick={handleClickNewPass}
                                icon={showNew ? <ViewOffIcon /> : <ViewIcon />}
                              />
                            </InputRightElement>
                          </InputGroup>
                          {errors.newPassword &&
                            <Text
                              fontSize='xs'
                              color='red.600'
                            >
                              ⚠ {errors.newPassword.message}
                            </Text>}
                        </Box>

                        <Box width='100%'>
                          <FormLabel alignSelf='flex-start'>Confirm Password</FormLabel>
                          <InputGroup>
                            <Input
                              autoComplete="off"
                              type={showConfirm ? 'text' : 'password'}
                              placeholder="Confirm Password"
                              focusBorderColor="gray.600"
                              {...register('confirmPassword', {
                                required: 'Confirm password is required',
                                validate: value => value === password || 'Passwords do not match'
                              })}
                            />
                            <InputRightElement>
                              <IconButton
                                aria-label="Show password"
                                variant='link'
                                onClick={handleClickConfirmPass}
                                icon={showConfirm ? <ViewOffIcon /> : <ViewIcon />}
                              />
                            </InputRightElement>
                          </InputGroup>
                          {errors.confirmPassword &&
                            <Text
                              fontSize='xs'
                              color='red.600'
                            >
                              ⚠ {errors.confirmPassword.message}
                            </Text>}
                        </Box>

                        {isSubmitted && 
                          <Alert status='success'>
                            <AlertIcon />
                            Modified password successfully!
                          </Alert>}

                        <Button
                          type='submit'
                          fontWeight={700}
                          isLoading={loading}
                          backgroundColor={"red.700"}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                          width='40%'
                        >
                          Submit
                        </Button>
                      </VStack>
                    </FormControl>
                  </form>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}
