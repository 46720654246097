import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { css } from "@emotion/css";

import { Center, Heading, Text, Input, Button } from "@chakra-ui/react";
import { Card, CardBody, Checkbox, Box, Select } from "@chakra-ui/react";
import {
  Accordion, AccordionItem, AccordionButton,
  AccordionPanel, AccordionIcon
} from "@chakra-ui/react";
import { FormControl, Stack } from "@chakra-ui/react";
import { RadioGroup, Radio } from "@chakra-ui/react";

import { getApi, postApi } from "../../utils/api";

type userProps = {
  first_name: string;
  last_name: string;
  email: string;
};

type formProps = {
  file: FileList;
  group_id: string;
  group_name: string;
  group_description: string;
};

type groupProps = {
  id: string;
  group_name: string;
  description: string;
  created_at: string;
};

const delay = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);


export function CreateMultipleUsers() {
  const [userLevel, setUserLevel] = useState("student");

  const [loading, setLoading] = useState(false);
  const [attachGroup, setAttachGroup] = useState(false);
  const [attachOption, setAttachOption] = useState("existing");
  const [groups, setGroups] = useState<groupProps[]>([]);
  const [groupId, setGroupId] = useState('');

  const { register, reset, handleSubmit,
    formState: { errors, isSubmitSuccessful }
  } = useForm<formProps>({});


  const onSubmit = async (data: formProps) => {

    if (data !== undefined) {
      const currentFile = data.file[0];

      var users = [];

      const fileReader = new FileReader();
      fileReader.readAsText(currentFile);
      fileReader.onloadend = async () => {
        const content = fileReader.result || "";
        const contentAsString = content?.toString();
        const arr = contentAsString?.split("\r\n");

        for (let i = 1; i < arr.length; i++) {
          const currentUser = arr[i].split(",");
          
          const user_info = {
            "first_name" : currentUser[0],
            "last_name" : currentUser[1],
            "email" : currentUser[2],
            "user_level" : userLevel
          }

          users.push(user_info);

          let group_id = groupId;

          if (attachGroup && attachOption === "create") {
            const group = await createGroup(data.group_name, data.group_description);
            group_id = group.id;
          };

          const response = await createUser(user_info.first_name, user_info.last_name, user_info.email, user_info.user_level);
          
          if (attachGroup) {
            await addToGroup(data.group_id, response.id);
          };
          
          await delay(1000);
        }
      };

      
    }
  }

  const createUser = async (
    firstName: string,
    lastName: string,
    email: string,
    userLevel: string
  ) => {
    var formData = {
      "current_user": {
        "user_level": "admin"
      },
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "user_level": userLevel
    }

    // MODIFY TO SEND MULTIPLE USERS
    if (setLoading) setLoading(true);

    var userIds : [{ user_id: string }] = [{ "user_id": '' }];
    let user = { "id": '' };

    const api = async () => {
      const response = await postApi("/users", formData);
      const jsonData = await response.json();
      user = jsonData;
      userIds.push({ "user_id" : user.id });

      await delay(1000);
    };

    try {
      userIds.pop();
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      console.log("error creating user");
    }
    if (setLoading) setLoading(false);

    // return userIds;
    return user;
  };

  const createGroup = async (
    group_name: string,
    description: string
  ) => {
    var formData = {
      "group_name": group_name,
      "description": description
    };

    let group = { "id": "" };

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/groups", formData);
      const jsonData = await response.json();
      group = jsonData;
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      console.log("error creating group");
    }
    if (setLoading) setLoading(false);

    return group;
  };

  const addToGroup = async (
    group_id: string,
    user_id: string
  ) => {
    var formData = {
      "group_id": group_id,
      "users": [
       { "user_id": user_id }
      ]
    };

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/users/groups", formData);
      const jsonData = await response.json();
      console.log(jsonData);
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      console.log("error adding user to group");
    }
    if (setLoading) setLoading(false);
  };

  useEffect(() => {
    const resetForm = async () => {
      if (isSubmitSuccessful) {
        await delay(1000);
        reset();
      }
    };

    resetForm();
  }, [isSubmitSuccessful]);

  return (
    <>
      <Center>
        <Card width="90vw" marginBottom='20px' align="center">
          <CardBody width="100%">
            <Accordion allowToggle>
              <AccordionItem border={"none"}>
                <AccordionButton width={"auto"} padding={0} style={{ backgroundColor: 'transparent' }}>
                  <Heading
                    as="h2"
                    size="md"
                    color={"blue.700"}
                    mr={3}
                  >
                    Create Multiple Users
                  </Heading>
                  <AccordionIcon color={"blue.800"} />
                </AccordionButton>


                <AccordionPanel border={"none"}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl width={"90%"} mx={"auto"}>
                      <Text mt={5}>Upload users file</Text>
                      <Input
                        type='file'
                        accept='.csv'
                        multiple={false}
                        {...register('file', {
                          required: { value: true, message: 'Select a file' },
                        })}
                        border='none'
                        marginTop='10px'
                        color='blue.600'
                        width='100%'
                        className={css`
                          ::file-selector-button {
                            border: none;
                            border-radius: 5px;
                            padding: 5px 10px;
                            margin-right: 20px;
                            font-size: 14px;
                          }
                        `}
                      />

                      {/* <AddToGroup /> */}

                      <Checkbox
                        onChange={(e) => setAttachGroup(e.target.checked)}
                        marginTop="20px"
                      >
                        Add to group
                      </Checkbox>

                      {attachGroup ? (
                        <RadioGroup
                          onChange={setAttachOption}
                          value={attachOption}
                          defaultValue={"existing"}
                          marginY="10px"
                          paddingLeft="10px"
                        >
                          <Radio value={"existing"}>From existing group</Radio>
                          <Radio value={"create"} paddingLeft="40px">Create group</Radio>
                        </RadioGroup>
                      ) : <></>}

                      {(attachGroup && attachOption === "create") ? (
                        <Box
                          width="70%"
                          paddingLeft="20px"
                        >
                          <Text>Group Name</Text>
                          <Input
                            type="text"
                            placeholder="Group name"
                            {...register('group_name', {
                              required: { value: true, message: 'This field is required' },
                              maxLength: { value: 30, message: 'Maximum length should be 30 characters' },
                              pattern: { value: /\w*\d*/gm, message: 'Only letters, digits and underscore allowed' }
                            })}
                            focusBorderColor="gray.600"
                          // name="group_name"
                          // value={newGroupInfo.group_name}
                          // onChange={onChangeGroup}
                          />
                          {errors.group_name &&
                            <Text
                              fontSize='xs'
                              color='red.600'
                              paddingTop={'5px'}
                            >
                              ⚠ {errors.group_name.message}
                            </Text>}

                          <Text marginTop="20px">Description</Text>
                          <Input
                            type="text"
                            placeholder="Group description"
                            {...register('group_description', {
                              required: { value: true, message: 'This field is required' },
                              maxLength: { value: 200, message: 'Maximum length is 200 characters' },
                              pattern: { value: /\w*\d*\s*[.,;"'()]*/gm, message: 'Only words, digits, white spaces and .,;()" allowed' }
                            })}
                            focusBorderColor="gray.600"
                          // name="description"
                          // value={newGroupInfo.description}
                          // onChange={onChangeGroup}
                          />
                          {errors.group_description &&
                            <Text
                              fontSize='xs'
                              color='red.600'
                              paddingTop={'5px'}
                            >
                              ⚠ {errors.group_description.message}
                            </Text>}

                        </Box>) : <></>}
                      {(attachGroup && attachOption === "existing") ? (
                        <Select
                          placeholder="Select group"
                          width="50%"
                          paddingLeft="20px"
                          onChange={(e) => setGroupId(e.currentTarget.value)}
                        >
                          {groups.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.group_name}</option>
                          ))}
                        </Select>
                      ) : <></>}

                      <Stack direction="row" justify={"space-between"}>
                        <RadioGroup
                          onChange={setUserLevel}
                          value={userLevel}
                          defaultValue='student'
                          marginTop="20px"
                        >
                          <Stack direction="column">
                            <Radio value='student' >Student</Radio>
                            <Radio value='admin' width={"fit-content"} isDisabled>Admin</Radio>
                          </Stack>
                        </RadioGroup>

                        <Button
                          type='submit'
                          fontWeight={700}
                          isLoading={loading}
                          backgroundColor={"red.700"}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                          alignSelf={"flex-end"}
                        >
                          Create
                        </Button>
                      </Stack>
                    </FormControl>
                  </form>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}