import styled from "@emotion/styled";
import { Center, Image, Stack, Text, Box, HStack, StackDivider } from "@chakra-ui/react";
import { css } from "@emotion/css";

import askcypertLogo from '../images/askcypert-logo.png';
import nsfLogo from '../images/nsf-logo.png';
import uofaLogo from '../images/UofA-logo.png';
import { NavLink } from "react-router-dom";

import { useContext, useState } from "react";
import { LoginContext } from "../../context/loginContext";

const StyledFooter = styled.div`
    margin-bottom: 0;
    position: fixed;
`;

const year = new Date().getFullYear();

export const Footer = () => {
  const { isLoggedIn } = useContext(LoginContext);

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
      setIsHover(true);
   };

   const handleMouseLeave = () => {
      setIsHover(false);
   };

  const link_style = {
    fontSize: '14px',
    color: isHover ? 'black' : '#4A5568',
    fontWeight: '400'
  };

  return(
    <>
      <Box backgroundColor={"gray.50"}
        position="absolute"
        width={"100vw"}
      >

        <HStack
          justify='center'
          divider={<StackDivider borderColor='gray.300' />}
          marginTop='20px'
          marginBottom='10px'
        >
          <NavLink to="/" 
            style={link_style}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >Home</NavLink>

          {isLoggedIn ? (
            <NavLink to="/myexperiments"
              className={css` 
            font-size: 14px; 
            color: #4A5568; 
            font-weight: 400;`}>Experiments</NavLink>
          ) : null}
          
          {isLoggedIn ? (
            <NavLink to="/user_agreement"
              className={css` 
            font-size: 14px; 
            color: #4A5568; 
            font-weight: 400;`}>User Agreement</NavLink>
          ) : null}
          
        </HStack>

        <Center>
          <Text color={"gray.600"} margin="10px 0">Sponsored by the NSF Cybersecurity Scholarship for Service</Text>
        </Center>

        <Center>
          <Stack spacing={"70px"} direction={"row"}>
            <Image src={nsfLogo}
              className={css`
                  max-height: 50px;
              `} />
            <Image src={askcypertLogo}
              className={css`
                  max-height: 50px;
              `} />
            <Image src={uofaLogo}
              className={css`
                  max-height: 45px;
              `} />
          </Stack>
        </Center>
        <Center>
          <Text color={"gray.600"}
            margin="10px 0"
            fontSize={"xs"}
          >Copyright {'\u00a9'} {year} AskCypert</Text>
        </Center>
      </Box>
    </>
  );
};
