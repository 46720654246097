// export const AwsConfigAuth = {
//     region: process.env.REACT_APP_AUTH_REGION,
//     userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
//     userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
//     cookieStorage: {
//         domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
//         path: "/",
//         expires: 365,
//         sameSite: "strict",
//         secure: true,
//     },
//     authenticationFlowType: "USER_SRP_AUTH",
// };

import config from "../../config.json";

export const AwsConfigAuth = {
    region: config.AUTH_REGION,
    userPoolId: config.AUTH_USER_POOL_ID,
    userPoolWebClientId: config.AUTH_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: config.AUTH_IDENTITY_POOL_ID,
    cookieStorage: {
        domain: config.AUTH_COOKIE_STORAGE_DOMAIN,
        path: "/",
        expires: 1,
        sameSite: "strict",
        secure: config.AUTH_COOKIE_STORAGE_DOMAIN !== 'localhost',
    },
    authenticationFlowType: "USER_SRP_AUTH"
};
