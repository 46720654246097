import { Heading, Flex, Image } from "@chakra-ui/react";
import { css } from "@emotion/css";

import askcypertLogo from '../images/askcypert-logo.png';

export function ExpandedLogo() {

  return (
    <>
      <Heading as="h1" size={"2xl"}
        className={css `
          text-align: center; 
          color: rgb(180,46,71);
          padding-bottom: 10px;
        `}>
          Cybersecurity Lab as a Service
      </Heading>
      <Heading as="h2" size="lg"
        className={css `
        text-align: center; 
        color: rgb(180,46,71);
        margin-bottom: 50px;
        `}>
          CLaaS
      </Heading>
      <Image src={askcypertLogo} 
        className={css `
          max-height: 200px; 
        `}/>
    </>
  );
}

export function ExpandedLogoSmall() {

  return (
    <>
    
      <Heading as="h1" size={"2xl"}
        className={css `
          text-align: center; 
          color: rgb(180,46,71);
          padding-bottom: 10px;
        `}>
          Cybersecurity Lab as a Service
      </Heading>
      <Heading as="h2" size="lg"
        className={css `
        text-align: center; 
        color: rgb(180,46,71);
        margin-bottom: 20px;
        `}>
          CLaaS
      </Heading>
      {/* <Image src={askcypertLogo} 
        className={css `
          max-height: 120px;
          margin-bottom: 30px;
        `}/> */}
    </>
  );
}