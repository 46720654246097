import { useContext, useState, useEffect } from "react";
import { Heading, Text, Box, Center, Spinner, VStack, Button, Tabs, HStack, List, ListItem, UnorderedList } from "@chakra-ui/react";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon } from "@chakra-ui/react";
import { Card, CardBody, Link } from "@chakra-ui/react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { RFBOptions } from "react-vnc/dist/types/lib/VncScreen";
import { VncScreen } from "react-vnc";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import styled from "@emotion/styled";
import { LoginContext } from "../context/loginContext";
import InstructionsViewer from "../components/experiments/instructionsViewer";
import { TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { getCookie } from "../utils/getCookie";
import { getApi, postApi } from "../utils/api";

import { Amplify, Storage } from "aws-amplify";
import { AwsConfigStorage } from "../components/config/storage";
import { stringify } from "querystring";
import { time } from "console";
import { delay, useDeprecatedInvertedScale } from "framer-motion";
import { Header } from "../components/header";

Amplify.configure({ Storage: AwsConfigStorage });

const StyledList = styled.ul`
display: flex;

li {
  list-style: none;
  color: rgb(102,117,139);
  margin: 0 30px;
  font-size: 20px;
}
`;

type instanceProps = {
  id: string;
  instance_id: string;
  instance_name: string;
  instance_state: string;
};

type instanceInfoProps = {
  InstanceId: string;
  InstanceState: string;
  InstanceDNS: string;
};

type experimentProps = {
  id: string;
  experiment_name: string;
  is_active: boolean;
  created_at: string;
  description: string;
  instructions_file_key: string;
};

type mergedInstancesProps = {
  InstanceId?: string | undefined;
  InstanceState?: string | undefined;
  InstanceDNS?: string | undefined;
  id: string;
  instance_id: string;
  instance_name: string;
  instance_state: string;
}

export default function Experiment() {
  const { id } = useParams();
  //console.log(id);
  const cookieData = getCookie(decodeURIComponent(document.cookie));
  const userId = cookieData.userData.username;

  const [instances, setInstances] = useState<instanceProps[]>([]);
  const [experiment, setExperiment] = useState<experimentProps>();
  const [instancesInfo, setInstancesInfo] = useState<instanceInfoProps[]>([]);
  const [mergedInstances, setMergedInstances] = useState<mergedInstancesProps[]>([]);

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useContext(LoginContext);
  const rfbOptionsProp = {
    credentials: {
      password: "claas2"
    }
  }

  const [fileURL, setfileURL] = useState('');


  const getInstructions = async (experiment_file_key: any) => {
    try {
      const fileKey = experiment_file_key;
      const fileURL = await Storage.get(fileKey);

      //console.log("File URL:", fileURL);
      setfileURL(fileURL);

    } catch (error) {
      console.error("Error fetching PDF file:", error);
    }
  };

  const startInstances = async (instances: instanceProps[]) => {
    setLoading(true);

    let instance_id_str = '';
    instances.map((item, index) => {
      instance_id_str += (item.instance_id + ',')
    });
    instance_id_str = instance_id_str.substring(0, instance_id_str.length - 1);

    try {
      const api_path_startInstances = "/startInstance?instanceId=" + instance_id_str;
      const startInstance_response = await postApi(api_path_startInstances, {});
      const startInstance_jsonData = await startInstance_response.json();
    }
    catch (error) {
      console.error("Error starting instances:", error);
    }

    setTimeout(async () => {
      try {
        const api_path_instancesInfo = "/instances?instanceId=" + instance_id_str;
        const instancesInfo_response = await getApi(api_path_instancesInfo);
        const instancesInfo_jsonData = await instancesInfo_response.json();

        setInstancesInfo(instancesInfo_jsonData);
      }
      catch (error) {
        console.error("Error reading instances info from EC2:", error);
      }
    }, 6000);

    setLoading(false);

    setTimeout(async () => {
      window.location.reload();
    }, 6100);

    // instancesInfo.map((item, index) => (
    //   item.InstanceDNS = 'ws://' + item.InstanceDNS + ':6080/vnc.html'
    // ));

  };

  const stopInstances = async (instances: instanceProps[]) => {
    setLoading(true);

    let instance_id_str = '';
    instances.map((item, index) => {
      instance_id_str += (item.instance_id + ',')
    });
    instance_id_str = instance_id_str.substring(0, instance_id_str.length - 1);

    try {
      const api_path_startInstances = "/stopInstance?instanceId=" + instance_id_str;
      const startInstance_response = await postApi(api_path_startInstances, {});
      const startInstance_jsonData = await startInstance_response.json();
    }
    catch (error) {
      console.error("Error stopping instances:", error);
    }

    setLoading(false);
  };


  useEffect(() => {
    const api = async () => {
      // Get experiment instances IDs
      const api_path_userInstances = "/users/experiments/instances?userId=" + userId + "&experimentId=" + id;
      const instances_response = await getApi(api_path_userInstances);
      const instances_jsonData = await instances_response.json();

      setInstances(instances_jsonData);

      // Get experiment info
      const api_path_experimentInfo = "/users/experimentInfo?experimentId=" + id;
      const experimentInfo_response = await getApi(api_path_experimentInfo);
      const experimentInfo_jsonData = await experimentInfo_response.json();

      setExperiment(experimentInfo_jsonData);

      // if (instancesInfo.length > 0) {
      //   instancesInfo.map((item, index) => (
      //     item.InstanceDNS = 'ws://' + item.InstanceDNS + ':6080/vnc.html'
      //   ));
      // }


      // console.log(instancesInfo);
    };
    api();
  }, []);

  useEffect(() => {
    const api = async () => {
      // Get instances EC2 info
      if (instances.length > 0) {
        let instance_id_str = '';
        instances.map((item, index) => {
          instance_id_str += (item.instance_id + ',')
        });
        instance_id_str = instance_id_str.substring(0, instance_id_str.length - 1);

        const api_path_instancesInfo = "/instances?instanceId=" + instance_id_str;
        let instancesInfo_response = await getApi(api_path_instancesInfo);
        instancesInfo_response = await getApi(api_path_instancesInfo);
        const instancesInfo_jsonData = await instancesInfo_response.json();

        setInstancesInfo(instancesInfo_jsonData['Instances']);
      }
    };
    api();
  }, [instances]);

  useEffect(() => {
    // Merge instances and instancesInfo here
    const mergedInstances = instances.map((instance) => {
      // Find the corresponding instance in instancesInfo based on instance_id
      const matchingInfo = instancesInfo.find((info) => info.InstanceId === instance.instance_id);

      // Merge the instance and info properties into a new object
      return {
        ...instance,  // Include properties from instances
        ...matchingInfo,  // Include properties from instancesInfo
      };
    }, []);

    // Set the merged instances in state
    setMergedInstances(mergedInstances);
  }, [instances, instancesInfo]);

  useEffect(() => {
    getInstructions(experiment?.instructions_file_key);
  }, [experiment]);

  // Now, mergedInstances contains the merged data
  //console.log(mergedInstances);
  const isClosed = false;

  //console.log(mergedInstances[0].InstanceDNS);
  if ((mergedInstances.length > 0 && mergedInstances[0].InstanceState === 'stopped')
    || (mergedInstances.length > 0 && mergedInstances[0].InstanceState === 'running' && mergedInstances[0].InstanceDNS)) {

    return (
      <>
        <Heading as="h2" size="lg" paddingY={"15px"} color={'blue.700'}>
          {experiment?.experiment_name}
        </Heading>

        <HStack justify={'space-between'} width={'80vw'} marginX={'auto'} marginBottom={'15px'}>
          <Text>{experiment?.description}</Text>

          {mergedInstances[0].InstanceState === 'running' ?
            <Button
              fontWeight={700}
              backgroundColor={"red.700"}
              color={"white"}
              _hover={{ bg: "red.800" }}
              isLoading={loading}
              onClick={() => stopInstances(instances)}
            >Stop experiment</Button> :
            <Button
              fontWeight={700}
              backgroundColor={"red.700"}
              color={"white"}
              _hover={{ bg: "red.800" }}
              isLoading={loading}
              onClick={() => startInstances(instances)}
            >Start experiment</Button>
          }
        </HStack>

        <Text width={"80vw"}
          marginX='auto'
          marginBottom={"20px"} fontStyle='italic'
        >Please allow 5 minutes for the VMs to start. If you stop the experiment and want to start it again, allow 5-10 minutes for the VMs to fully stop.</Text>

        <Card width="80vw" marginX="auto" marginBottom={"20px"} align="center">
          <CardBody width='100%'>
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton width={"auto"} padding={0} style={{ backgroundColor: 'transparent' }}>
                  <Heading
                    as="h2"
                    size="md"
                    color={"blue.700"}
                    mr={3}
                  >
                    Enable VM connection
                  </Heading>
                  <AccordionIcon color={"blue.800"} />
                </AccordionButton>

                <AccordionPanel>
                  <UnorderedList>
                    <ListItem>After you start the experiment, go to the following links.</ListItem>
                    <ListItem>Accept the risk of each VM, this will enable CLaaS to connect to the VMs.</ListItem>
                    <ListItem>Once you see the <b>Warning</b>, follow these instructions according to the browser you use:</ListItem>
                    <UnorderedList>
                      <ListItem>
                        <b>Firefox: </b> Select Advanced &gt; Accept the risk and continue
                      </ListItem>
                      <ListItem>
                        <b>Chrome: </b> Select Advanced &gt; Proceed to ...
                      </ListItem>
                      <ListItem>
                        <b>Safari: </b> Select Show Details &gt; Visit this website
                      </ListItem>
                    </UnorderedList>
                  </UnorderedList>
                  <Text paddingTop={'20px'} fontWeight={'bold'}>VMs:</Text>

                  {mergedInstances.map((item, index) => (
                    item.InstanceState === 'running' ?
                      <List>
                        <ListItem key={item.id}>
                          <Link href={'https://' + item.InstanceDNS + ':6080/vnc.html'}
                            isExternal paddingLeft={'10px'} color='blue.600'
                          >
                            {item.instance_name}
                          </Link>
                        </ListItem>
                      </List>
                      : <></>
                  ))}

                  <Text fontStyle={'italic'} paddingTop='10px'>Note: The links will show once you start the experiment</Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>

        {/* <InstructionsViewer onTab={false}/> */}

        <Tabs variant={"solid-rounded"} colorScheme={"red"}>
          <TabList ml={"5vw"}>
            {mergedInstances.map((item, index) => (
              <Tab key={item.id}>{item.instance_name}</Tab>
            ))}
            <Tab>Instructions</Tab>
          </TabList>

          {/* <InstructionsViewer onTab={false}/> */}

          <TabPanels>
            {mergedInstances.map((item, index) => (
              <TabPanel key={item.id}>

                {item.InstanceState === 'stopped' ?
                  <VStack zIndex={"10"}>
                    <Box width="80vw" height={"75vh"} borderWidth={'1px'} borderColor={"gray.200"}>
                      <Text fontWeight={"800"} color={"gray.600"} fontSize={"x-large"}
                        textAlign={"center"} marginTop={"32.5vh"}
                      >Start the experiment to turn ON the VM</Text>
                    </Box>
                  </VStack>
                  :
                  <Box
                    backgroundColor={"black"}
                    width={"80vw"}
                    height={"75vh"}
                    m={"0 auto"}
                  >
                    <VncScreen
                      //url="ws://ec2-35-86-177-176.us-west-2.compute.amazonaws.com:6080/vnc.html"
                      url={'wss://' + item.InstanceDNS + ':6080/vnc.html'}
                      rfbOptions={rfbOptionsProp}
                      style={{
                        width: '80vw',
                        height: '75vh'
                      }}
                      resizeSession
                      loadingUI={
                        <VStack zIndex={"10"} position="absolute" mt="-75vh">
                          <Box width="80vw" height={"32vh"}></Box>
                          <Text fontWeight={"600"} color={"white"}>Connecting...</Text>
                          <Spinner
                            size='xl'
                            thickness="3px"
                            color="gray.100"
                            speed="0.8s"
                          />
                        </VStack>
                      }
                    />
                    {/* <iframe src={'https://' + item.InstanceDNS + ':6080/vnc.html'} width='100%' height='100%'/> */}
                  </Box>
                }
              </TabPanel>
            ))
            }

            <TabPanel>
              <Box
                width={"80vw"}
                height={'75vh'}
                backgroundColor={'white'}
                m={'0 auto'}
              >
                <InstructionsViewer onTab={true} fileURL={fileURL} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </>
    );
  } else {
    return (
      <div>Loading...</div> // You can replace this with a loading indicator
    );
  }
}

