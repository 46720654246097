import React, { Dispatch, SetStateAction } from "react";

type ContextProps = {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  userId: string;
  setUserId: Dispatch<SetStateAction<string>>;
};

export const LoginContext = React.createContext<Partial<ContextProps>>({isLoggedIn: false});