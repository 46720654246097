import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Image } from "@chakra-ui/react";
import { css } from "@emotion/css";
import askcypertLogo from '../images/askcypert-logo.png';

const StyledLogo = styled.div`
  margin-left: 10px;
  color: rgb(181,17,60);
  font-weight: 600;
`;

export const Logo = () => (
  <StyledLogo>
    <Link to="/">
      <Image src={require('./../images/askcypert-logo.png')} alt={'AskCypert Logo'}
          className={css `
            max-height: 50px; 
          `}/>
    </Link>
  </StyledLogo>
);
