import config from './../config.json';

export async function getApi(path: string) {
  const api_path = config.API_ENDPOINT + path;
  const options = {
    method: "GET",
    headers: {
      'Accept': 'application/json'
    }
  };

  const response = await fetch(api_path, options);

  return response;
};

export async function postApi(path: string, data: any) {
  const api_path = config.API_ENDPOINT + path;
  const options = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  const response = await fetch(api_path, options);

  return response;
};

export async function deleteApi(path: string, data: any) {
  const api_path = config.API_ENDPOINT + path;
  const options = {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  const response = await fetch(api_path, options);

  return response;
};