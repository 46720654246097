import { useState, useContext } from "react";
import { Auth } from "aws-amplify";

import { LoginContext } from "../context/loginContext";
import { CredentialsContext } from "../context/credentialsContext";

import { css } from "@emotion/css";
import { keyframes } from "@emotion/react";
import { Flex, Box, Heading, FormControl, Input, Button, Text } from "@chakra-ui/react";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export default function SendVerificationCode() {
  document.title = "CLaaS - Reset Password";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [newPasswordReq, setNewPasswordReq] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loginError, setLoginError] = useState(false);

  const { loginUsername, setLoginUsername, tempPassword, setTempPassword } = useContext(
    CredentialsContext
  );

  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const sendVerificationCode = async (username: string) => {
    try{
      const response = await Auth.forgotPassword(username);
    }
    catch (err) {
      console.log(err);
    }
    
  };

  return(
    <>
      <Flex
            minHeight="calc(100vh - 263px)"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              p={[6, 8]}
              bg="white"
              borderRadius="lg"
              boxShadow="1px 1px 5px #E2E8F0"
              maxW="sm"
              w="90%"
              borderWidth="1px"
              css={css`
            opacity: 0;
            animation: ${fadeIn} 0.4s ease-in-out forwards;
            animation-delay: 0.1s;
          `}
            >
              <Box textAlign="center">
                <Heading as="h3" fontSize="larger" mb={8}
                  className={css`
              color: rgb(102,117,139);
              `}
                >
                  Reset your password
                </Heading>
              </Box>
              <Box my={4} textAlign="left">
                <form autoComplete="off">
                  <FormControl>
                    <Input
                      autoComplete="off"
                      type="email"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      focusBorderColor="gray.600"
                    />
                  </FormControl>
                  <Button
                    width="full"
                    mt='35px'
                    fontWeight={500}
                    onClick={() => sendVerificationCode(username)}
                    isLoading={loading}
                  >
                    Send verification code
                  </Button>
                </form>
                </Box>
                <Box 
                  width='fit-content' 
                  mx='auto' 
                  my='auto' 
                  textColor='blue.600' 
                  _hover={{textDecoration: 'underline'}}
                >
                </Box>
            </Box>
          </Flex>
    </>
  );
}