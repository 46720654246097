import { useContext } from "react";
import { Flex } from "@chakra-ui/react";
import { ExpandedLogo } from "../components/brand/expanded-logo";
import { LoginContext } from "../context/loginContext";

export default function Home() {
  document.title = "CLaaS - Home";

  const { isLoggedIn } = useContext(LoginContext);

  return (
    <>
      <Flex
        minHeight="calc(100vh - 263px)"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <ExpandedLogo />
      </Flex>
    </>
  );
}
