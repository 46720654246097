import * as React from "react";

import { Route, Navigate } from "react-router";

import { LoginContext } from "../../context/loginContext";

interface IProps {
  path?: string;
  component: React.ComponentType<any>;
}

export const PrivateRoute: React.FC<IProps> = ({
    component: RouteComponent
}) => {
    const { isLoggedIn } = React.useContext(LoginContext);

    if (isLoggedIn) {
        return <RouteComponent />;
    }

    return <Navigate to="/login" />
};

// export default function PrivateRoute({
//   path,
//   component
// }: IProps) {
//   const { isLoggedIn } = React.useContext(LoginContext);

//   return (
//     <Route
//       path={path}
//       element={
//         isLoggedIn ? <Route path={path}/> : <Navigate to="/login"/>
//       }
//     />
//   );
// }
