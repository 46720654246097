export function getCookie(cookie: string) {
  const cookieData = {
    "idToken": '',
    "accessToken": '',
    "refreshToken": '',
    "lastAuthUser": '',
    "userData": {
      "username": '',
      "userLevel": '',
      "emailVerified": '',
      "firstName": '',
      "lastName": '',
      "email": '',
      "userAgreement": ''
    }
  }

  if (cookie.length > 0) {
    const cookieArray = cookie
      .split(';')
      .map(v => v.split('='));

    for(let index = 0; index < cookieArray.length; index++){
      if (cookieArray[index][0].indexOf('idToken') !== -1){
        cookieData.idToken = cookieArray[index][1];
      } 
      else if (cookieArray[index][0].indexOf('accessToken') !== -1){
        cookieData.accessToken = cookieArray[index][1];
      } 
      else if (cookieArray[index][0].indexOf('refreshToken') !== -1){
        cookieData.refreshToken = cookieArray[index][1];
      }
      else if (cookieArray[index][0].indexOf('LastAuthUser') !== -1){
        cookieData.lastAuthUser = cookieArray[index][1];
      }
      else if (cookieArray[index][0].indexOf('userData') !== -1){
        const userDataJson = JSON.parse(cookieArray[index][1]);

        cookieData.userData.username = userDataJson.Username;

        for (let userDataIndex = 0; userDataIndex < userDataJson.UserAttributes.length; userDataIndex++){
          if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('user_level') !== -1){
            cookieData.userData.userLevel = userDataJson.UserAttributes[userDataIndex].Value;
          }
          else if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('email_verified') !== -1){
            cookieData.userData.emailVerified = userDataJson.UserAttributes[userDataIndex].Value;
          }
          else if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('family_name') !== -1){
            cookieData.userData.lastName = userDataJson.UserAttributes[userDataIndex].Value;
          }
          else if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('name') !== -1){
            cookieData.userData.firstName = userDataJson.UserAttributes[userDataIndex].Value;
          }
          else if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('email') !== -1){
            cookieData.userData.email = userDataJson.UserAttributes[userDataIndex].Value;
          }
          else if(userDataJson.UserAttributes[userDataIndex].Name.indexOf('userAgreement') !== -1){
            cookieData.userData.userAgreement = userDataJson.UserAttributes[userDataIndex].Value;
          }
        }
      }
    }
  }

  return cookieData;
}