import { Center, Heading, Button, Skeleton, Checkbox, HStack, IconButton, Text, Select } from "@chakra-ui/react";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { Table, TableContainer, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { RepeatIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { useEffect, useState } from "react";

import { getApi, deleteApi } from "./../../utils/api";

type groupProps = {
  id: string;
  group_name: string;
  description: string;
  created_at: string;
};

const delay = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export function GroupsTable() {
  const [loading, setLoading] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const [loadingGroups, setLoadingGroups] = useState(false);

  const [groups, setGroups] = useState<groupProps[]>([]);
  const [groupIds, setGroupIds] = useState<Array<string>>([]);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    const api = async () => {
      if (setLoadingGroups) setLoadingGroups(true);

      try {
        const response = await getApi("/groups");
        const jsonData = await response.json();

        setGroups(jsonData.body);
      }
      catch (err) {
        console.log('Unable to get groups', err);
      }

      if (setLoadingGroups) setLoadingGroups(false);
    };
    api();
  }, [isUpdated]);

  const deleteGroup = async (
    group_ids: string[],
  ) => {
    if (setLoading) setLoading(true);

    for (let group = 0; group < group_ids.length; group++) {
      var formData = {
        "group_id": group_ids[group]
      }

      const api = async () => {
        const response = await deleteApi("/groups", formData);
      };

      try {
        await api();
        await delay(1000);
      }
      catch (err) {
        if (setLoading) setLoading(false);
        console.log("error deleting group");
      }

    }
    if (setLoading) setLoading(false);
    if (setGroupIds) setGroupIds([]);
    isUpdated ? setIsUpdated(false) : setIsUpdated(true);
  };

  const reload = async () => {
    isUpdated ? setIsUpdated(false) : setIsUpdated(true);
    await delay(1000);
  };

  const skeletonArray = [0, 1, 2];
  const skeletonItemsArray = [0, 1, 2, 3, 4];

  return (
    <>
      <Center>
        <Card width="90vw" mb={8}>
          <CardHeader>
            <HStack justify='space-between'>
              <Heading as="h2" size="md" color={"blue.700"}>Groups</Heading>
              <IconButton
                aria-label="Reload"
                background='transparent'
                _hover={{ background: 'transparent' }}
                onClick={() => reload()}
                icon={<RepeatIcon />} />
            </HStack>
          </CardHeader>
          <CardBody>


            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th><Center>Select</Center></Th>
                    <Th>Group Name</Th>
                    <Th>Description</Th>
                    <Th>Creation Date</Th>
                  </Tr>
                </Thead>

                {loadingGroups ?
                  <Tbody>
                    {skeletonArray.map((index) => (
                      <Tr key={index}>
                        {skeletonItemsArray.map((index) => (
                          <Td key={index}><Skeleton height='20px' /></Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody> :

                  <Tbody>

                    {groups
                      .slice((page - 1) * rows, page * rows)
                      .map((item, index) => (
                        <Tr key={index} _hover={{
                          background: "gray.50"
                        }}>
                          <Td key={item.id}>
                            <Center>
                              <Checkbox
                                value={item.id}
                                isChecked={groupIds.includes(item.id)}
                                onChange={(e) => {
                                  if (groupIds.includes(e.target.value)) {
                                    const newIds = groupIds.filter((id) => id !== e.target.value);
                                    setGroupIds(newIds);
                                  } else {
                                    const newIds = [...groupIds];
                                    newIds.push(e.target.value);
                                    setGroupIds(newIds);
                                  }
                                }}>
                              </Checkbox>
                            </Center>
                          </Td>
                          <Td key={item.group_name}>{item.group_name}</Td>
                          <Td key={item.description}>{item.description}</Td>
                          <Td key={item.created_at}>{item.created_at.split(' ')[0]}</Td>
                        </Tr>
                      )
                      )}
                  </Tbody>
                }

              </Table>
            </TableContainer>
            <HStack marginTop="10px" justify='space-evenly'>
              <IconButton
                aria-label="Previous Page"
                background='transparent'
                _hover={{ background: 'transparent' }}
                isDisabled={page === 1}
                onClick={() => {
                  setPage(page - 1);
                }}
                icon={<ChevronLeftIcon />} />

              <Text fontSize={'sm'}>{((page - 1) * rows) + 1} - {(page * rows < groups.length) ? (page * rows) : (groups.length)} of {groups.length}</Text>

              <IconButton
                aria-label="Next Page"
                background='transparent'
                _hover={{ background: 'transparent' }}
                isDisabled={page >= (groups.length) / rows}
                onClick={() => {
                  setPage(page + 1);
                }}
                icon={<ChevronRightIcon />} />
            </HStack>



            <HStack justify='space-between' width='95%' mx='auto'>
              <Text
                // fontWeight='bold'
                color={"gray.600"}
              >Total groups: <b>{groups.length}</b></Text>

              <Select
                maxWidth='fit-content'
                onChange={(e: any) => {
                  setRows(Number(e.target.value));
                  setPage(1);
                }}
                defaultValue={'10'}>
                <option value='5'>5 per page</option>
                <option value='10'>10 per page</option>
                <option value='20'>20 per page</option>
                <option value='50'>50 per page</option>
              </Select>
            </HStack>
            <Text
              // fontWeight='bold' 
              width='95%' mx='auto' paddingBottom='10px' color={"gray.600"}
            >Selected <b>{groupIds.length}</b> groups</Text>

            <HStack my='10px' width='95%' mx='auto'>
              <Button
                fontWeight={700}
                backgroundColor={"red.700"}
                color={"white"}
                _hover={{ bg: "red.800" }}
                onClick={() => deleteGroup(groupIds)}//console.log(userIds)}//createUser(firstName, lastName, email, userLevel)}
                isLoading={loading}
              >
                Delete
              </Button>
            </HStack>
            
            {/* <Text marginTop="30px" marginBottom="10px">Assign experiment</Text>
            <Select width={400} placeholder="Select experiment">
              <option>Test Lab</option>
            </Select>
            <Button
              mt={4}
              fontWeight={700}
              //onClick={() => createUser(firstName, lastName, email, userLevel)}
              isLoading={loading}
            >
              Assign
            </Button> */}
          </CardBody>
        </Card>
      </Center>
    </>
  );
}