import { useState, useContext } from "react";
import { Navigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "../components/config/auth";

import { LoginContext } from "../context/loginContext";
import { CredentialsContext } from "../context/credentialsContext";

import { ExpandedLogoSmall } from "../components/brand/expanded-logo";

import { css } from "@emotion/css";
import { keyframes } from "@emotion/react";
import { Flex, Box, Heading, FormControl, Input, Button, Text } from "@chakra-ui/react";


Amplify.configure({ Auth: AwsConfigAuth });

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export default function Login() {
  document.title = "CLaaS - Login";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [newPasswordReq, setNewPasswordReq] = useState(false);

  const [loading, setLoading] = useState(false);

  const [loginError, setLoginError] = useState(false);

  const { loginUsername, setLoginUsername, tempPassword, setTempPassword } = useContext(
    CredentialsContext
  );

  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);

  const onLogin = async (username: string, password: string) => {
    if (setLoading) setLoading(true);

    try {
      const response = await Auth.signIn(username, password);

      if (response.challengeName == 'NEW_PASSWORD_REQUIRED') {

        localStorage.setItem('NewPasswordRequired', 'true');
        if (setNewPasswordReq) setNewPasswordReq(true);
        if (setLoginUsername) setLoginUsername(username);
        if (setTempPassword) setTempPassword(password);

      }
      else {
        if (setIsLoggedIn && response) {
          setIsLoggedIn(true);
        };
      }
    } catch (err) {
      if (setLoading) setLoading(false);
      if (setLoginError) setLoginError(true);
    }
    if (setLoading) setLoading(false);

  };

  if (newPasswordReq) {
    return <Navigate to="/new_password" />;
  }
  else {
    if (isLoggedIn) {
      return <Navigate to="/experiments"/>;
    } else {
      return (
        <>
          <Flex
            minHeight="calc(100vh - 263px)"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ExpandedLogoSmall />
            <Box
              p={[6, 8]}
              bg="white"
              borderRadius="lg"
              boxShadow="1px 1px 5px #E2E8F0"
              maxW="sm"
              w="90%"
              borderWidth="1px"
              css={css`
            opacity: 0;
            animation: ${fadeIn} 0.4s ease-in-out forwards;
            animation-delay: 0.1s;
          `}
            >
              <Box textAlign="center">
                <Heading as="h2" size="lg" mb={8}
                  className={css`
              color: rgb(102,117,139);
              `}
                >
                  Login
                </Heading>
              </Box>
              <Box my={4} textAlign="left">
                <form autoComplete="off">
                  <FormControl>
                    <Input
                      autoComplete="off"
                      type="email"
                      placeholder="Email"
                      value={username}
                      onChange={(e: any) => setUsername(e.target.value)}
                      focusBorderColor="gray.600"
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <Input
                      autoComplete="off"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e: any) => setPassword(e.target.value)}
                      focusBorderColor="gray.600"
                    />
                  </FormControl>
                  {loginError ? <Text
                    fontSize='xs'
                    color='red.600'
                    paddingTop='5px'
                    marginBottom='-22px'
                  >
                    ⚠ Invalid username or password
                  </Text> : <></>}
                  <Button
                    width="full"
                    mt='35px'
                    fontWeight={500}
                    onClick={() => onLogin(username, password)}
                    isLoading={loading}
                  >
                    Login
                  </Button>
                </form>
              </Box>
              {/* <Box
                width='fit-content'
                mx='auto'
                my='auto'
                textColor='blue.600'
                _hover={{ textDecoration: 'underline' }}
              >
                <NavLink to='/get_verification_code'>
                  Forgot password
                </NavLink>
              </Box> */}
            </Box>
          </Flex>
        </>
      );
    }
  }
}
