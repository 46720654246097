import { Center, Heading, Button, Skeleton, Checkbox, HStack, IconButton, Text, Select, Box, RadioGroup, Radio } from "@chakra-ui/react";
import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { Table, TableContainer, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { RepeatIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon } from "@chakra-ui/react";

import { SetStateAction, useEffect, useState } from "react";

import { getApi, deleteApi, postApi } from "./../../utils/api";

type userProps = {
  id: string;
  cognito_id: string;
  first_name: string;
  last_name: string;
  email: string;
  subnet: string;
  user_level: string;
  username: string;
};

type groupProps = {
  id: string;
  group_name: string;
  description: string;
  created_at: string;
};

type experimentProps = {
  id: string;
  experiment_name: string;
  description: string;
  created_at: string;
};

const delay = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export function UsersTable() {
  const [loading, setLoading] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const [loadingUsers, setLoadingUsers] = useState(false);

  const [users, setUsers] = useState<userProps[]>([]);
  const [userIds, setUserIds] = useState<Array<string>>([]);
  const allChecked = userIds.length === users.length;
  const isIndeterminate = userIds.length > 0 && !allChecked;

  const [groups, setGroups] = useState<groupProps[]>([]);
  const [groupId, setGroupId] = useState('');
  const [groupValue, setGroupValue] = useState('assign');

  const [experiments, setExperiments] = useState<experimentProps[]>([]);
  const [experimentId, setExperimentId] = useState('');

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const [sortKey, setSortKey] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);

  useEffect(() => {
    const api = async () => {
      if (setLoadingUsers) setLoadingUsers(true);

      const users_response = await getApi("/users");
      const users_jsonData = await users_response.json();

      setUsers(users_jsonData.body);

      if (setLoadingUsers) setLoadingUsers(false);

      const groups_response = await getApi("/groups");
      const groups_jsonData = await groups_response.json();

      setGroups(groups_jsonData.body);

      const experiments_response = await getApi("/experiments");
      const experiments_jsonData = await experiments_response.json();

      setExperiments(experiments_jsonData['body']);
    };
    api();
  }, [isUpdated]);

  const sortUsers = (users: userProps[], key: string, order: 'asc' | 'desc' | null) => {
    return [...users].sort((a, b) => {
      if (key === 'first_name' || key === 'last_name' || key === 'user_level' || key === 'email') {
        const aValue = a[key].toLowerCase();
        const bValue = b[key].toLowerCase();
        if (order === 'asc') {
          return aValue.localeCompare(bValue);
        } else if (order === 'desc') {
          return bValue.localeCompare(aValue);
        }
      }
      return 0;
    });
  };

  // Function to handle sorting
  const handleSort = (key: string) => {
    // If the same column is clicked, reverse the sort order
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the new sorting key
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  const deleteUser = async (
    user_ids: string[],
  ) => {
    if (setLoading) setLoading(true);

    for (let user = 0; user < user_ids.length; user++) {
      var formData = {
        "cognito_id": user_ids[user]
      }

      const api = async () => {
        const response = await deleteApi("/users", formData);
      };

      try {
        await api();
        await delay(1000);
      }
      catch (err) {
        if (setLoading) setLoading(false);
        console.log("error deleting user");
      }

    }
    if (setLoading) setLoading(false);
    isUpdated ? setIsUpdated(false) : setIsUpdated(true);
  };

  const addToGroup = async (
    group_id: string,
    userIds: string[]
  ) => {
    var users = [];

    for (let i = 0; i < userIds.length; i++) {
      const user_aux = { "user_id": userIds[i] };
      users.push(user_aux);
    }

    var formData = {
      "group_id": group_id,
      "users": users
    };

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/users/groups", formData);
      const jsonData = await response.json();
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      console.log("error adding user to group");
    };

    if (setLoading) setLoading(false);
  };

  const assignExperiment = async (
    experiment_id: string,
    userIds: string[]
  ) => {
    var users = [];

    for (let i = 0; i < userIds.length; i++) {
      const user_aux = { "user_id": userIds[i] };
      users.push(user_aux);
    }

    var formData = {
      "experiment_id": experiment_id,
      "users": users
    };

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/users/experiments", formData);
      const jsonData = await response.json();
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      console.log("error assigning experiment to user");
    };

    if (setLoading) setLoading(false);
  };

  const reload = async () => {
    isUpdated ? setIsUpdated(false) : setIsUpdated(true);
    if (setPage) setPage(1);
    await delay(1000);
  };

  const skeletonArray = [0, 1, 2];
  const skeletonItemsArray = [0, 1, 2, 3, 4];
  const sortedUsers = sortUsers(users, sortKey, sortOrder);

  if (experiments.length > 0) {
    return (
      <>
        <Center>
          <Card width="90vw" marginBottom={'30px'}>
            <CardHeader>
              <HStack justify='space-between'>
                <Heading as="h2" size="md" color={"blue.700"}>Users</Heading>
                <IconButton
                  aria-label="Reload"
                  background='transparent'
                  _hover={{ background: 'transparent' }}
                  onClick={() => reload()}
                  icon={<RepeatIcon />} />
              </HStack>
            </CardHeader>
            <CardBody>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th><Center><Checkbox
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => {
                          if (userIds.length === users.length) {
                            setUserIds([]);
                          } else {
                            const newIds = [...userIds];
                            users.map((item) => (
                              !userIds.includes(item.id) ? newIds.push(item.id) : newIds
                            ));
                            setUserIds(newIds);
                          }
                        }}
                      ></Checkbox></Center></Th>
                      <Th
                        onClick={() => handleSort('first_name')}
                        style={{ cursor: 'pointer' }}
                      >
                        First Name {sortKey === 'first_name' && sortOrder && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </Th>
                      <Th
                        onClick={() => handleSort('last_name')}
                        style={{ cursor: 'pointer' }}
                      >
                        Last Name {sortKey === 'last_name' && sortOrder && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </Th>
                      <Th
                        onClick={() => handleSort('email')}
                        style={{ cursor: 'pointer' }}
                      >
                        Email {sortKey === 'email' && sortOrder && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </Th>
                      <Th
                        onClick={() => handleSort('user_level')}
                        style={{ cursor: 'pointer' }}
                      ><Center>
                        User Level {sortKey === 'user_level' && sortOrder && (
                          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
                        )}
                      </Center></Th>
                    </Tr>
                  </Thead>

                  {loadingUsers ?
                    <Tbody>
                      {skeletonArray.map((index) => (
                        <Tr key={index}>
                          {skeletonItemsArray.map((index) => (
                            <Td key={index}><Skeleton height='20px' /></Td>
                          ))}
                        </Tr>
                      ))}
                    </Tbody> :

                    <Tbody>

                      {sortedUsers
                        // .sort(test)
                        .slice((page - 1) * rows, page * rows)
                        .map((item, index) => (
                          <Tr key={index} _hover={{
                            background: "gray.50"
                          }}>
                            <Td key={item.id}>
                              <Center><Checkbox
                                value={item.id}
                                isChecked={userIds.includes(item.id)}
                                onChange={(e) => {
                                  if (userIds.includes(e.target.value)) {
                                    const newIds = userIds.filter((id) => id !== e.target.value);
                                    setUserIds(newIds);
                                  } else {
                                    const newIds = [...userIds];
                                    newIds.push(e.target.value);
                                    setUserIds(newIds)
                                  }
                                }}>
                              </Checkbox></Center>
                            </Td>
                            <Td key={item.first_name}>{item.first_name}</Td>
                            <Td key={item.last_name}>{item.last_name}</Td>
                            <Td key={item.email}>{item.email}</Td>
                            <Td key={item.user_level}><Center>{item.user_level}</Center></Td>
                          </Tr>
                        )
                        )}
                    </Tbody>
                  }

                </Table>
              </TableContainer>
              <HStack marginTop="10px" justify='space-evenly'>
                <IconButton
                  aria-label="Previous Page"
                  background='transparent'
                  _hover={{ background: 'transparent' }}
                  isDisabled={page === 1}
                  onClick={() => setPage(page - 1)}
                  icon={<ChevronLeftIcon />} />

                <Text fontSize={'sm'}>{((page - 1) * rows) + 1} - {(page * rows < users.length) ? (page * rows) : (users.length)} of {users.length}</Text>

                <IconButton
                  aria-label="Next Page"
                  background='transparent'
                  _hover={{ background: 'transparent' }}
                  isDisabled={page >= (users.length) / rows}
                  onClick={() => setPage(page + 1)}
                  icon={<ChevronRightIcon />} />
              </HStack>

              <HStack justify='space-between' width='95%' mx='auto'>
                <Text
                  // fontWeight='bold' 
                  color={"gray.600"}
                >Total users: <b>{users.length}</b></Text>

                <Select
                  maxWidth='fit-content'
                  onChange={(e: any) => setRows(Number(e.target.value))}
                  defaultValue={'10'}>
                  <option value='5'>5 per page</option>
                  <option value='10'>10 per page</option>
                  <option value='20'>20 per page</option>
                  <option value='50'>50 per page</option>
                </Select>
              </HStack>

              <Text
                // fontWeight='bold' 
                width='95%' mx='auto' paddingBottom='10px' color={"gray.600"}
              >Selected <b>{userIds.length}</b> users</Text>

              <HStack my='10px' width='95%' mx='auto'>
                <Button
                  fontWeight={700}
                  backgroundColor={"red.700"}
                  color={"white"}
                  _hover={{ bg: "red.800" }}
                  onClick={() => deleteUser(userIds)}//console.log(userIds)}//createUser(firstName, lastName, email, userLevel)}
                  isLoading={loading}
                >
                  Delete
                </Button>
              </HStack>

              <Box
                width={'95%'}
                marginX='auto'
                // rounded='5px'
                // borderWidth='1px'
                // borderColor='gray.200'
                padding={'15px 0'}
                marginTop='40px'
                marginBottom='5px'
              >
                <Accordion allowToggle>
                  <AccordionItem border={'none'}>
                    <AccordionButton
                      padding={0}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <Heading
                        as="h3"
                        size='sm'
                        color={"gray.600"}
                      // paddingX='10px'
                      >
                        Group Assignment
                      </Heading>
                      <AccordionIcon color={"blue.800"} />
                    </AccordionButton>
                    <AccordionPanel>
                      <HStack
                        width={'85%'}
                        marginX='auto'
                        justify='space-between'
                        marginTop={'10px'}
                      >
                        <Select
                          width='80%'
                          onChange={(e) => setGroupId(e.currentTarget.value)}
                        >
                          {groups.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.group_name}</option>
                          ))}
                        </Select>
                        <Button
                          paddingX={'30px'}
                          fontWeight={700}
                          backgroundColor={'red.700'}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                          onClick={() => addToGroup(groupId, userIds)}
                          isLoading={loading}
                        >
                          Submit
                        </Button>
                      </HStack>
                      <HStack
                        width={'85%'}
                        marginX='auto'
                        marginTop={'15px'}>
                        <RadioGroup
                          onChange={setGroupValue}
                          value={groupValue}>
                          <Radio paddingRight={'20px'} value="assign">
                            Add
                          </Radio>
                          <Radio value="remove">
                            Remove
                          </Radio>
                        </RadioGroup>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>

              <Box
                width={'95%'}
                marginX='auto'
                // rounded='5px'
                // borderWidth='1px'
                // borderColor='gray.200'
                padding={'15px 0'}
                marginBottom={'10px'}
              >
                <Accordion allowToggle>
                  <AccordionItem border={'none'}>
                    <AccordionButton
                      padding={0}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      <Heading
                        as="h3"
                        size='sm'
                        color={"gray.600"}
                      // paddingX='10px'
                      >
                        Experiment Assignment
                      </Heading>
                      <AccordionIcon color={"blue.800"} />
                    </AccordionButton>
                    <AccordionPanel>
                      <HStack
                        width={'85%'}
                        marginX='auto'
                        justify='space-between'
                        marginTop={'10px'}
                      >
                        <Select
                          width='80%'
                          onChange={(e) => setExperimentId(e.currentTarget.value)}
                        >
                          {experiments.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.experiment_name}</option>
                          ))}

                        </Select>
                        <Button
                          paddingX={'30px'}
                          fontWeight={700}
                          backgroundColor={'red.700'}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                          //onClick={() => assignExperiment(experimentId, userIds)}//console.log(userIds)}//createUser(firstName, lastName, email, userLevel)}
                          isLoading={loading}
                        >
                          Submit
                        </Button>
                      </HStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </CardBody>
          </Card>
        </Center>
      </>
    );
  }
  else {
    return (
      <div>Loading...</div>
    );
  }
}