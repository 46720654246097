import { Logo } from "./logo";
import { Nav } from "./nav";
import styled from "@emotion/styled";

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 52px;
  background: rgb(245, 248, 254);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 9999; 
`;


export const Header = () => (
  <StyledHeader>
    <Logo />
    <Nav />
  </StyledHeader>
);
