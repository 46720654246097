import { useState } from "react";
import { Box, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Draggable from "react-draggable";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { css } from "@emotion/css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function InstructionsViewer({ onTab, fileURL }: { onTab: boolean, fileURL: string }) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentSuccess = (pdfInfo: any) => {
    setNumPages(pdfInfo._pdfInfo.numPages);
  }

  const pageWidth = Math.round(window.innerWidth * 75 / 100);

  const isClosed = true;

  return (
    onTab ? (
      <><HStack 
          marginBottom={'10px'} 
          justify='space-between' 
          marginX='43%'
        >
          <IconButton
            aria-label="Previous Page"
            isDisabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            icon={<ChevronLeftIcon />} />
          <Text>{pageNumber}</Text>
          <IconButton
            aria-label="Next Page"
            isDisabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            icon={<ChevronRightIcon />} /> 
        </HStack>
      <VStack justify={'space-between'} overflow={'scroll'} backgroundColor={'white'}>
      <Box height={'70vh'} marginX={'auto'} backgroundColor='white'>
        <Document file={fileURL} onLoadSuccess={onDocumentSuccess}>
          <Page
            pageNumber={pageNumber}
            width={pageWidth} className={css`
            margin-bottom: 10px;
          `}/>
        </Document>
        </Box>
      </VStack>
      </>
    ) :
      (
        <Draggable>
          <Box
            bg="gray.100"
            padding="10px"
            rounded={"5px"}
            width={"fit-content"}
            position="absolute"
            zIndex={999}
            hidden={isClosed}
          >
            <Document file={fileURL} onLoadSuccess={onDocumentSuccess}>
              <Page pageNumber={pageNumber} scale={0.7} />
            </Document>
          </Box>
        </Draggable>
      )
  );
}