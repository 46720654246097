import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Input, Text, Center, Button, Stack, Alert, AlertIcon, CloseButton, AlertDescription, Collapse, Box, Icon, HStack, VStack, Spacer, background } from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { FormControl, IconButton, Flex } from "@chakra-ui/react";

import { Amplify, Storage } from "aws-amplify";

import { postApi } from "../../utils/api";
import { SmallCloseIcon } from "@chakra-ui/icons";

import { AwsConfigStorage } from "../config/storage";
import { create } from "domain";

Amplify.configure({ Storage: AwsConfigStorage });

type experimentProps = {
  experiment_name: string,
  experiment_description: string,
  experiment_file_key: string,
  amis: amiProps[]
};

type amiProps = {
  ami_id: string,
  ami_name: string,
  ami_description: string,
};

const delay = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export function CreateExperiment() {

  const [loading, setLoading] = useState(false);
  const [successStatus, setSuccessStatus] = useState(true);
  const [responseData, setResponseData] = useState({
    Message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const [amis, setAmis] = useState<amiProps[]>([]);

  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileKey, setFileKey] = useState('');

  const { register, reset, handleSubmit,
    formState: { errors, isSubmitSuccessful }
  } = useForm<experimentProps>({
    defaultValues: {
      experiment_name: '',
      experiment_description: '',
      experiment_file_key: '',
      amis: []
    }
  });

  const handleAddAMIs = () => {
    const ami_values = [...amis];

    ami_values.push({
      ami_id: '',
      ami_name: '',
      ami_description: ''
    });

    setAmis(ami_values);
  };

  const handleRemoveAMIs = (index: number) => {
    const ami_values = [...amis];

    ami_values.splice(index, 1);

    setAmis(ami_values);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
  
    try {
      // Generate a unique key for the file in S3
      const newfileKey = `uploads/${Date.now()}-${selectedFile.name}`;

      setFileKey(newfileKey);
  
      // Upload the file to S3
      await Storage.put(newfileKey, selectedFile, {
        contentType: selectedFile.type,
      });
  
      // Do something with the uploaded file (e.g., store the key in your database)
  
      console.log(`File uploaded successfully to S3 with key: ${newfileKey}`);
      return newfileKey;

    } catch (error) {
      console.error('Error uploading file to S3:', error);
    }
  };

  const createExperiment = async (experimentData: experimentProps) => {
    var formData = experimentData

    console.log(formData);

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/experiments", formData);
      const jsonData = await response.json();

      if (setResponseData) setResponseData(jsonData);
      if (setSuccessStatus) {
        if (response.status >= 200 && response.status <= 299) setSuccessStatus(true);
        else if (response.status >= 400) setSuccessStatus(false);
      }
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      if (setResponseData) setResponseData({ Message: 'Error creating experiment' });
    }

    if (setAlertVisible) setAlertVisible(true);

    if (setLoading) setLoading(false);
  };

  const onSubmit = async (data: experimentProps) => {
    if (setAlertVisible) setAlertVisible(false);

    const resp = await handleFileUpload() || "";

    data.experiment_file_key = resp;
    console.log(data);

    
    createExperiment(data);
  };

  useEffect(() => {
    amis.pop();

    const resetForm = async () => {
      if (isSubmitSuccessful) {
        await delay(1000);
        reset({
          experiment_name: '',
          experiment_description: '',
          experiment_file_key: '',
          amis: []
        }, { keepIsSubmitted: true });
      }
    };

    resetForm();
  }, [isSubmitSuccessful]);

  return (
    <>
      <Center>
        <Card width="90vw" margin="30px auto" align="center">
          <CardBody width="100%">
            <Accordion allowToggle>
              <AccordionItem border={"none"}>
                <AccordionButton width={"auto"} padding={0} style={{ backgroundColor: 'transparent' }}>
                  <Heading
                    as="h2"
                    size="md"
                    color={"blue.700"}
                    mr={3}
                  >
                    Create Experiment
                  </Heading>
                  <AccordionIcon color={"blue.800"} />
                </AccordionButton>

                <AccordionPanel border={"none"}>
                  {successStatus ? (
                    <Collapse in={alertVisible} unmountOnExit animateOpacity>
                      <Alert
                        status='success'
                        width={'90%'}
                        rounded={'5px'}
                        mx={"auto"}
                        hidden={!alertVisible}
                      >
                        <AlertIcon />
                        <AlertDescription width={"100%"}>
                          {responseData.Message}
                        </AlertDescription>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          size={"sm"}
                          onClick={() => setAlertVisible(false)}
                        />
                      </Alert>
                    </Collapse>
                  ) : (
                    <Collapse in={alertVisible} unmountOnExit animateOpacity>
                      <Alert
                        status='error'
                        width={'90%'}
                        rounded={'5px'}
                        mx={"auto"}
                        hidden={!alertVisible}
                      >
                        <AlertIcon />
                        <AlertDescription width={"100%"}>
                          {responseData.Message}
                        </AlertDescription>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          size={"sm"}
                          onClick={() => setAlertVisible(false)}
                        />
                      </Alert>
                    </Collapse>
                  )}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl width={"90%"} mx={"auto"}>
                      <Text mt={5}>Experiment Name</Text>
                      <Input
                        autoComplete="off"
                        type="text"
                        placeholder="Experiment Name"
                        {...register('experiment_name', {
                          required: { value: true, message: 'This field is required' },
                          maxLength: { value: 30, message: 'Maximum length should be 30 characters' },
                          pattern: { value: /^[A-Za-z0-9\s]+$/gm, message: 'Only letters, digits and spaces allowed' }
                        })}
                        focusBorderColor="gray.600"
                      />
                      {errors.experiment_name &&
                        <Text
                          fontSize='xs'
                          color='red.600'
                          paddingTop={'5px'}
                        >
                          ⚠ {errors.experiment_name.message}
                        </Text>}

                      <Text marginTop="20px">Description</Text>
                      <Input
                        autoComplete="off"
                        type="text"
                        placeholder="Description"
                        {...register('experiment_description', {
                          required: { value: true, message: 'This field is required' },
                          maxLength: { value: 200, message: 'Maximum length is 200 characters' },
                          pattern: { value: /^[a-zA-Z0-9\s'" ;,.()]+$/gm, message: 'Only letters, digits, white spaces and . , ; () " allowed' }
                        })}
                        focusBorderColor="gray.600"
                      />
                      {errors.experiment_description &&
                        <Text
                          fontSize='xs'
                          color='red.600'
                          paddingTop={'5px'}
                        >
                          ⚠ {errors.experiment_description.message}
                        </Text>}

                        <Text>Instructions</Text>
                        <Input
                          type="file"
                          onChange={(e) => {
                            const file = e.target.files?.[0];
                            setSelectedFile(file);
                          }}
                        />

                      {amis.length > 0 && (
                        <>
                          {amis.map((field, index) => (
                            <Flex
                              key={index}
                              marginX='auto'
                              marginY='15px'
                              direction='column'
                            >
                              <HStack justify='space-between'>
                                <Text>AMI: {index + 1}</Text>
                                <IconButton
                                  aria-label='Remove AMI'
                                  icon={<SmallCloseIcon />}
                                  width='10px'
                                  background='transparent'
                                  color='gray.600'
                                  _hover={{
                                    background: "transparent",
                                    color: "red.800"
                                  }}
                                  onClick={() => handleRemoveAMIs(index)}
                                />
                              </HStack>

                              <HStack justify='space-between'>
                                <Box width='49%'>
                                  <Text>AMI name</Text>
                                  <Input type="text" {...register(`amis.${index}.ami_name`)}/>
                                </Box>

                                <Box width='49%'>
                                  <Text>AMI ID</Text>
                                  <Input type="text" {...register(`amis.${index}.ami_id`)}/>
                                </Box>
                              </HStack>

                              <Text>AMI description</Text>
                              <Input type="text" {...register(`amis.${index}.ami_description`)}/>

                            </Flex>
                          ))}
                        </>
                      )}
                      <Button 
                        variant='link'
                        onClick={() => handleAddAMIs()}
                        background='transparent'
                        color='blue.500'
                        textDecor='underline'
                        _hover={{ color: "blue.700" }}
                        paddingY='10px'
                      > + Add AMI</Button>

                      <Stack direction="row" marginTop="20px" justify={"right"}>
                        <Button
                          type='submit'
                          fontWeight={700}
                          isLoading={loading}
                          backgroundColor={"red.700"}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                        >
                          Create
                        </Button>
                      </Stack>
                    </FormControl>
                  </form>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}