import { CreateExperiment } from "../components/experimentsSettings/createExperiment";

export default function Settings() {

  return (
    <>
    <CreateExperiment />
    </>
  );
}
