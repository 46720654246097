import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, Button, Text } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

import styled from "@emotion/styled";

import { LoginContext } from "./context/loginContext";
import { CredentialsContext } from "./context/credentialsContext";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { PrivateRoute } from "./components/privateRoute/privateRoute";

import theme from "./theme";
import './theme/styles.css';

import Home from "./pages/home";
import About from "./pages/about";
import Profile from "./pages/profile";
import Login from "./pages/login";
import MyExperiments from "./pages/myExperiments";
import Users from "./pages/users";
import Settings from "./pages/settings";
import Experiment from "./pages/experiment";
import UserAgreement from "./pages/userAgreement";
import NewPassword from "./pages/newPassword";
import SendVerificationCode from "./pages/sendVerificationCode";
import Groups from "./pages/groups";

import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "./components/config/auth";
import { getCookie } from "./utils/getCookie";

Amplify.configure({ Auth: AwsConfigAuth });

const StyledBody = styled.div`
  margin: 60px auto 20px auto;
  width: 90%;
  position: relative;
  min-height: calc(100vh - 263px);
`;

export default function App() {
  const cookie = getCookie(decodeURIComponent(document.cookie));

  const [isLoggedIn, setIsLoggedIn] = useState(cookie.userData.username.length > 0);


  const [loginUsername, setLoginUsername] = useState('');
  const [tempPassword, setTempPassword] = useState('');


  const [userId, setUserId] = useState(cookie.userData.username);
  const [role, setRole] = useState(cookie.userData.userLevel);

  const [isAdmin, setIsAdmin] = useState(cookie.userData.userLevel);

  let userAgreement = true;
  let newPasswordRequired = false;
  // let isAdmin = (role === 'admin');

  if (localStorage.getItem('NewPasswordRequired') !== null) newPasswordRequired = true;

  if (cookie.userData.userAgreement.length > 0) {
    userAgreement = JSON.parse(cookie.userData.userAgreement);
  }

  const location = useLocation();

  const logout = async () => {
    setShowModal(false);
    await Auth.signOut();
    if (setIsLoggedIn) setIsLoggedIn(false);
    window.location.reload();
  };

  const AreYouThere = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
    <>
    <Modal isCentered isOpen={showModal} onClose={onClose} trapFocus={false}>
      <ModalOverlay backdropFilter={'blur(5px)'} background={'none'}/>

      <ModalContent padding={'10px'}>
        <ModalHeader>Are you still there?</ModalHeader>
        <ModalBody>
          <Text>You have been idle for 45 minutes</Text>
          <Text>This session will expire in: <Timer /></Text>
        </ModalBody>
        <Button 
          onClick={() => setShowModal(false)}
          width={"30%"}
          marginX={'auto'}
          backgroundColor={"red.700"}
          color={"white"}
          _hover={{ bg: "red.800" }}
          marginTop={"20px"}
        >I'm here</Button>
      </ModalContent>
    </Modal>
    </>
    )
  };

  const [showModal, setShowModal] = useState(false);

  let sessionTimeout : NodeJS.Timeout;
  let idleTimeout: NodeJS.Timeout;

  const resetTimeout = () => {
    clearTimeout(sessionTimeout);

    idleTimeout = setTimeout(() => {
      setShowModal(true);
      sessionTimeout = setTimeout(logout, 15 * 60 * 1000);
    }, 45 * 60 * 1000);
  };

  // useEffect(() => {
  //   if (isLoggedIn === true) {
  //     resetTimeout();

  //     const handleActivity = () => {
  //       resetTimeout();
  //     };

  //     window.addEventListener("mousemove", handleActivity);
  //     window.addEventListener("keypress", handleActivity);
  //     window.addEventListener("touchstart", handleActivity);

  //     return () => {
  //       window.removeEventListener("mousemove", handleActivity);
  //       window.removeEventListener("keypress", handleActivity);
  //       window.removeEventListener("touchstart", handleActivity);
  //     };
  //   }
  // });

  //setIsAdmin(role === 'admin');

  return (
    <>
      <LoginContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn
        }}
      >
        <CredentialsContext.Provider
          value={{
            loginUsername,
            setLoginUsername,
            tempPassword,
            setTempPassword
          }}
        >
          <ChakraProvider theme={theme}>
            {/* <AreYouThere /> */}
            <Header key={location.key} />
            <StyledBody>
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/about" element={<About />} /> */}
                <Route path="/profile"
                  element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> : <PrivateRoute component={Profile} />
                  } />
                {/* <Route path="/myexperiments"
                  element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> : <PrivateRoute component={MyExperiments} />
                  } /> */}
                <Route path="/users"
                  element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> :
                      (isAdmin ? <PrivateRoute component={Users} /> : <PrivateRoute component={MyExperiments} />)
                  } />
                <Route path="/settings"
                  element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> :
                      (isAdmin ? <PrivateRoute component={Settings} /> : <PrivateRoute component={MyExperiments} />)
                  } />
                <Route path="/groups"
                  element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> :
                      (isAdmin ? <PrivateRoute component={Groups} /> : <PrivateRoute component={MyExperiments} />)
                  } />
                <Route path="/experiments" >
                  <Route index element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> : <PrivateRoute component={MyExperiments} />
                  } />
                  <Route path=":id" element={
                    !userAgreement ? <PrivateRoute component={UserAgreement} /> : <PrivateRoute component={Experiment} />
                  } />
                </Route>
                  
                <Route path="/login/" element={<Login />} />
                <Route path="/user_agreement" element={<PrivateRoute component={UserAgreement} />} />
                <Route path="/new_password" element={newPasswordRequired ? <NewPassword /> : <Login />} />
                <Route path="/get_verification_code" element={<SendVerificationCode />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </StyledBody>
            <Footer />
          </ChakraProvider>
        </CredentialsContext.Provider>
      </LoginContext.Provider>
    </>
  );
};

export function Timer() {
  const [timer, setTimer] = useState<number>(15*60);
  //const [timerMin, setTimerMin] = useState<number>(15*60);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer-1);
    }, 1000);

    // const intervalMin = setInterval(() => {
    //   setTimerMin((prevTimerMin) => prevTimerMin-1);
    // }, 1000);

    return() => { 
      clearInterval(interval);
      //clearInterval(intervalMin);
    };
  });

  return (
    <><b>{Math.floor(timer/60)}:{String(timer%60).padStart(2, '0')}</b></>
  );
};