import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { Input, Text, Center, Button, Stack, Alert, AlertIcon, CloseButton, AlertDescription, Collapse } from "@chakra-ui/react";
import { Card, CardBody } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/react";
import { FormControl } from "@chakra-ui/react";

import { postApi } from "../../utils/api";

type groupProps = {
  group_name: string,
  description: string
};

const delay = (ms: number) => new Promise(
  resolve => setTimeout(resolve, ms)
);

export function CreateGroup() {

  const [loading, setLoading] = useState(false);
  const [successStatus, setSuccessStatus] = useState(true);
  const [responseData, setResponseData] = useState({
    Message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const { register, reset, handleSubmit,
    formState: { errors, isSubmitSuccessful }
  } = useForm<groupProps>({
    defaultValues: {
      group_name: '',
      description: ''
    }
  });

  const createGroup = async (groupName: string, description: string) => {
    var formData = {
      "current_user": {
        "user_level": "admin"
      },
      "group_name": groupName,
      "description": description
    }

    if (setLoading) setLoading(true);

    const api = async () => {
      const response = await postApi("/groups", formData);
      const jsonData = await response.json();

      if (setResponseData) setResponseData(jsonData);
      if (setSuccessStatus) {
        if (response.status >= 200 && response.status <= 299) setSuccessStatus(true);
        else if (response.status >= 400) setSuccessStatus(false);
      }
    };

    try {
      await api();
    }
    catch (err) {
      if (setLoading) setLoading(false);
      if (setResponseData) setResponseData({ Message: 'Error creating group' });
    }

    if (setAlertVisible) setAlertVisible(true);

    if (setLoading) setLoading(false);
  };

  const onSubmit = (data: groupProps) => {
    if (setAlertVisible) setAlertVisible(false);
    createGroup(data.group_name, data.description);
  };

  useEffect(() => {
    const resetForm = async () => {
      if (isSubmitSuccessful) {
        await delay(1000);
        reset({
          group_name: '',
          description: ''
        }, { keepIsSubmitted: true });
      }
    };

    resetForm();
  }, [isSubmitSuccessful]);

  return (
    <>
      <Center>
        <Card width="90vw" margin="30px auto" align="center">
          <CardBody width="100%">
            <Accordion allowToggle>
              <AccordionItem border={"none"}>
                <AccordionButton width={"auto"} padding={0} style={{ backgroundColor: 'transparent' }}>
                  <Heading
                    as="h2"
                    size="md"
                    color={"blue.700"}
                    mr={3}
                  >
                    Create Group
                  </Heading>
                  <AccordionIcon color={"blue.800"} />
                </AccordionButton>

                <AccordionPanel border={"none"}>
                  {successStatus ? (
                    <Collapse in={alertVisible} unmountOnExit animateOpacity>
                      <Alert
                        status='success'
                        width={'90%'}
                        rounded={'5px'}
                        mx={"auto"}
                        hidden={!alertVisible}
                      >
                        <AlertIcon />
                        <AlertDescription width={"100%"}>
                          {responseData.Message}
                        </AlertDescription>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          size={"sm"}
                          onClick={() => setAlertVisible(false)}
                        />
                      </Alert>
                    </Collapse>
                  ) : (
                    <Collapse in={alertVisible} unmountOnExit animateOpacity>
                      <Alert
                        status='error'
                        width={'90%'}
                        rounded={'5px'}
                        mx={"auto"}
                        hidden={!alertVisible}
                      >
                        <AlertIcon />
                        <AlertDescription width={"100%"}>
                          {responseData.Message}
                        </AlertDescription>
                        <CloseButton
                          alignSelf='flex-start'
                          position='relative'
                          right={-1}
                          top={-1}
                          size={"sm"}
                          onClick={() => setAlertVisible(false)}
                        />
                      </Alert>
                    </Collapse>
                  )}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl width={"90%"} mx={"auto"}>
                      <Text mt={5}>Group Name</Text>
                      <Input
                        autoComplete="off"
                        type="text"
                        placeholder="Group Name"
                        {...register('group_name', {
                          required: { value: true, message: 'This field is required' },
                          maxLength: { value: 30, message: 'Maximum length should be 30 characters' },
                          pattern: { value: /\w*\d*/gm, message: 'Only letters, digits and underscore allowed' }
                        })}
                        focusBorderColor="gray.600"
                      />
                      {errors.group_name &&
                        <Text
                        fontSize='xs'
                        color='red.600'
                        paddingTop={'5px'}
                      >
                        ⚠ {errors.group_name.message}
                      </Text>}

                      <Text marginTop="20px">Description</Text>
                      <Input
                        autoComplete="off"
                        type="text"
                        placeholder="Description"
                        {...register('description', {
                          required: { value: true, message: 'This field is required' },
                          maxLength: { value: 200, message: 'Maximum length is 200 characters' },
                          pattern: { value: /\w*\d*\s*[.,;"'()]*/gm, message: 'Only words, digits, white spaces and .,;()" allowed' }
                        })}
                        focusBorderColor="gray.600"
                      />
                      {errors.description &&
                        <Text
                        fontSize='xs'
                        color='red.600'
                        paddingTop={'5px'}
                      >
                        ⚠ {errors.description.message}
                      </Text>}

                      <Stack direction="row" marginTop="20px" justify={"right"}>
                        <Button
                          type='submit'
                          fontWeight={700}
                          isLoading={loading}
                          backgroundColor={"red.700"}
                          color={"white"}
                          _hover={{ bg: "red.800" }}
                        >
                          Create
                        </Button>
                      </Stack>
                    </FormControl>
                  </form>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </Center>
    </>
  );
}