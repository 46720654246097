import { UsersTable } from "../components/users/usersTable";
import { CreateUser } from "../components/users/createUser";
import { CreateMultipleUsers } from "../components/users/createMultipleUsers";

export default function Users() {
  document.title = "CLaaS - Users Settings";

  return (
    <>
      <CreateUser />
      <CreateMultipleUsers />
      <UsersTable />
    </>
  );
}
