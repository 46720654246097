import { useState } from "react";
import { Heading, Text, Link, UnorderedList, ListItem, Box, Checkbox, HStack, Button } from "@chakra-ui/react";
import { css } from "@emotion/css";

import { Amplify, Auth } from "aws-amplify";
import { AwsConfigAuth } from "../components/config/auth";

import { getCookie } from "../utils/getCookie";

Amplify.configure({ Auth: AwsConfigAuth });

export default function UserAgreement() {
  document.title = "CLaaS - User Agreement";

  const cookieData = getCookie(decodeURIComponent(document.cookie));
  const userData = cookieData.userData;

  let userAgreement = false;

  try {
    userAgreement = JSON.parse(userData.userAgreement);
  } catch {
    console.log('Not logged in');
  }
  
  // console.log(userAgreement);

  const [checkedAgree, setCheckedAgree] = useState(false);

  const userAgrees = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Auth.updateUserAttributes(user, {
        'custom:userAgreement': 'true'
      });

      if (result) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Heading
        className={css`
        color: rgb(180,46,71);
        padding-top: 30px;
        margin-bottom: 20px;
      `}>
        User Agreement
      </Heading>
      <Box width={'95%'} mx='auto'>
        <Text paddingBottom='10px'>Please, carefully read the following user agreement.</Text>
        <Text paddingBottom='10px'>The use of the virtual computers/networks provided within this environment
          (The Cybersecurity Lab as a Service website) is a privilege granted to
          registered members only.
        </Text>
        <Text>While using this account, you are agreeing to:</Text>
        <UnorderedList paddingLeft='15px' spacing={1}>
          <ListItem>Take no actions which violate the University of Arizona <Link
            color='blue.700'
            href="https://public.azregents.edu/Policy%20Manual/5-308-Student%20Code%20of%20Conduct.pdf"
            isExternal
          >
            Codes of Conduct
          </Link> or <Link
            color='blue.700'
            href="https://deanofstudents.arizona.edu/policies/code-academic-integrity"
            isExternal
          >
              Academic Integrity
            </Link>,
            the University of Arizona <Link
              color='blue.700'
              href="https://policy.arizona.edu/classified-staff-human-resources-policy-manual"
              isExternal
            >
              Classified Staff Human Resources Policy Manual
            </Link>, the University <Link
              color='blue.700'
              href="https://policy.arizona.edu/university-handbook-appointed-personnel"
              isExternal
            >
              Handbook for Appointed Personnel</Link>,
            or other applicable policy or law.
          </ListItem>
          <ListItem>Use these resources only for purposes consistent with the University’s
            mission and applicable policy or law. Inappropriate use includes, but is
            not limited to
          </ListItem>
          <UnorderedList paddingLeft='15px' spacing={1}>
            <ListItem>
              Sending harassing messages or in any way harassing other computer users.
            </ListItem>
            <ListItem>
              Gaining or attempting to gain access to accounts or files without
              permission on any computer or network system.
            </ListItem>
            <ListItem>
              Making unauthorized copies or distributing copies without permission of
              any copyright-protected software, or other copyrighted or trademarked
              material, regardless of source.
            </ListItem>
            <ListItem>
              Taking actions that threaten the security or capacity of computer or network
              systems outside the virtual environment assigned to you, or which destroy
              damage, or overload, these resources.
            </ListItem>
            <ListItem>
              Violating any applicable law or policy.
            </ListItem>
          </UnorderedList>
        </UnorderedList>
        <Text paddingTop='10px'>
          Failure to abide by these policies will result in the revocation of your
          privileges to use the CLaaS computing and network resources as well as possible
          legal action should you violate any laws.
        </Text>
        {userAgreement ? <Text marginBottom='50px'></Text> :
          <HStack marginTop='30px' justify='space-between'>
            <Checkbox my='auto' color='gray.600'
              onChange={(e) => setCheckedAgree(e.target.checked)}>
              I agree to the CLaaS User Agreement</Checkbox>
            <Button
              isDisabled={!checkedAgree}
              fontWeight={700}
              backgroundColor={"red.700"}
              color={"white"}
              _hover={{ bg: "red.800" }}
              onClick={userAgrees}
            >
              Submit
            </Button>
          </HStack>
        }

      </Box>
    </>
  );
}