import { CreateGroup } from "../components/groups/createGroup";
import { GroupsTable } from "../components/groups/groupsTable";
import { UsersGroupsTable } from "../components/groups/usersGroupsTable"

export default function Groups() {
  document.title = "CLaaS - Groups Settings";

  return (
    <>
      <CreateGroup />
      <GroupsTable />
      <UsersGroupsTable />
    </>
  );
}
