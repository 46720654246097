import { useState, useContext } from "react";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";
import { Account } from "./account";

import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import styled from "@emotion/styled";
import { LoginContext } from "../../context/loginContext";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { getCookie } from "../../utils/getCookie";

const StyledNav = styled.ul`
  display: flex;

  li {
    list-style: none;
    color: rgb(102,117,139);
    margin: 0 15px;
  }
`;

export const Nav = () => {
  const cookie = getCookie(decodeURIComponent(document.cookie));
  const [role, setRole] = useState(cookie.userData.userLevel);

  const { isLoggedIn } = useContext(LoginContext);
  
  let isAdmin = (role === 'admin');

  return(
    <StyledNav>
      {/* <li>
        <NavLink to="/about">About</NavLink>
      </li> */}
      {isLoggedIn ? (
        <li>
          <NavLink to="/experiments">My Experiments</NavLink>
        </li>
      ): null}
      {isLoggedIn ? (
        <li>
          <NavLink to="/profile">Profile</NavLink>
        </li>
      ): null}
      {/* {isLoggedIn ? (
        <li>
          <NavLink to="/users">Users</NavLink>
        </li>
      ): null} */}
      {(isLoggedIn && isAdmin) ? (
        <li>
          <Menu>
            <MenuButton>
              Settings <ChevronDownIcon />
            </MenuButton>
            <MenuList border={'none'} background='gray.50' minWidth='fit-content'>
              <MenuItem background='gray.50' _hover={{ background: 'gray.200' }}>
                <NavLink to="/users" className={css`
                  width: 100%;
                  margin-right: 5px;
                  margin-left: 5px;
                `}>Users</NavLink>
              </MenuItem>
              <MenuItem background='gray.50' _hover={{ background: 'gray.200' }}>
                <NavLink to="/settings" className={css`
                  width: 100%;
                  margin-right: 5px;
                  margin-left: 5px;
                `}>Experiments</NavLink>
              </MenuItem>
              <MenuItem background='gray.50' _hover={{ background: 'gray.200' }}>
                <NavLink to="/groups" className={css`
                  width: 100%;
                  margin-right: 5px;
                  margin-left: 5px;
                `}>Groups</NavLink>
              </MenuItem>
            </MenuList>
          </Menu>
        </li>
      ): null}
      <Account />
    </StyledNav>
  );
};
